
// import React from 'react';
// import './App.css';  // You can keep this for global styles
// import Menu from './Components/Menu';
// import Banner from './Components/Banner';
// import Footer from './Components/Footer';
// import Subscribe from './Components/Subscribe';
// import Login from './Components/Login';

// function App() {
//   return (
//     <div className="App">
//       <Menu />
//       <Banner />
//       <Footer />
//     </div>
//   );
// }

// export default App;

// -----------Working code-----------------------------

import React from 'react';
import './App.css';  // You can keep this for global styles
import Menu from './Components/Menu';
import Banner from './Components/Banner';
import Footer from './Components/Footer';
import Subscribe from './Components/Subscribe';
import { HashRouter  as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './Components/Login';
import SignUp from './Components/Bannner1';
import QRPage from './Components/QRCode';
import Scanqr from './Components/Scanqr';

import PrivacyPolicy from './Components/Privacypolicy';

function MainContent() {
  const location = useLocation(); // Use location to check the current route

  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Banner />} />
        <Route path="/login" element={<Login />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Scanqr" element={<Scanqr />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <MainContent />
      </div>
    </Router>
  );
}

export default App;